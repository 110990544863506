
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
            <v-container v-else class="my-2">
                <v-layout row wrap px-4 mb-4>
                    <v-flex xs9>
                        <v-breadcrumbs class="px-0" :items="$store.getters.language.data.customers.list_path">
                            <template v-slot:item="{item}">
                                <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                    {{ item.title }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-flex>
                    <v-flex xs3></v-flex>
                </v-layout>
                <form @submit.prevent="addCustomers" autocomplete="off">
                    <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_name" type="text" :label="$store.getters.language.data.customers.customer_name" dense
                            class="mx-1"  filled outlined required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_phone" type="text" :label="$store.getters.language.data.customers.customer_phone" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                
                   <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_firebase_uid" type="text" :label="$store.getters.language.data.customers.customer_firebase_uid" dense
                            class="mx-1"  filled outlined>
                        </v-text-field>
                    </v-flex> -->
                   <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="customers.customer_location" type="textarea" :label="$store.getters.language.data.customers.customer_location" dense
                            class="mx-1" filled outlined>
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.customers.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card>
            <v-card-text>
                <v-data-table :headers="headers"   v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="customer_id">
                    <template v-slot:[`item.customer_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/customers-list/'+item.customer_id"  color="teal" class="mx-1">
                            <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-btn color="error" icon class="mx-1" @click="selectCustomers(item)" >
                                <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.customer_status`]="{ item }">
                        <div>
                            <v-chip color="success" small v-if="item.customer_status == 'enable'"> Enabled </v-chip>
                            <v-chip color="error" small v-else> Disabled </v-chip>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteCustomersList">{{$store.getters.language.data.customers.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>
<v-dialog v-model="delete_dialog" persistent max-width="400">
    <v-card>
        <v-card-title>
            {{$store.getters.language.data.customers.delete_question}}
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="elevation-0" @click="delete_dialog = false">
                {{$store.getters.language.data.customers.cancel_btn}}
            </v-btn>
            <v-btn color="error" class="elevation-0" @click="deleteCustomers(selected_customers.customer_id)">
                {{$store.getters.language.data.customers.yes_btn}}
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/customers.request.js'
    export default {
        data() {
            return {
                customers: {},
                search: '',
                loading : false,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                
                selected_customers : {},
                delete_dialog: false,
                headers: [
                    { 
                        text: this.$store.getters.language.data.customers.customer_name,
                        align: 'start',
                        sortable: true,
                        value: 'customer_name',
                    },
                    { 
                        text: this.$store.getters.language.data.customers.customer_phone,
                        align: 'start',
                        sortable: true,
                        value: 'customer_phone',
                    },
                    // { 
                    //     text: this.$store.getters.language.data.customers.customer_status,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'customer_status',
                    // },
                    { 
                        text: this.$store.getters.language.data.customers.customer_location,
                        align: 'start',
                        sortable: true,
                        value: 'customer_location',
                    },   
                    {
                        text: this.$store.getters.language.data.actions,
                        align: 'start',
                        sortable: true,
                        value: 'customer_id',
                    }
                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.customers_list
            },
            buttonDisable() {
                return !this.customers.customer_name ||
                    !this.customers.customer_phone
            }
        },
        mounted(){
            
        },
        methods: {
            addCustomers() {
                this.loading_btn = true
                this.customers.store_id = this.$store.getters.store.store_id;
                this.customers.customer_status = 'enable';
                requests.createCustomers(this.customers).then(r => {
                    if (r.status == 200) {
                        this.customers = {}
                        this.$store.state.customers_list.push(r.data.new_data)
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.add_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteCustomers(customer_id) {
                requests.deleteCustomers(customer_id).then(r => {
                    this.delete_dialog = false
                    if (r.status == 200) {
                        this.$store.state.customers_list = this.$store.state.customers_list.filter(f =>{
                            return f.customer_id != customer_id
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            deleteCustomersList() {
                let ids = this.selected_rows.map(m => m.customer_id)
                requests.deleteCustomersList(ids).then(r => {
                    if (r.status == 200) {
                        this.$store.state.customers_list = this.$store.state.customers_list.filter(f =>{  
                            return ids.indexOf(f.customer_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.delete_success,
                            color: 'success'
                        }
                        this.selected_rows = [];
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
            },
            
            selectCustomers(i) {
                this.selected_customers = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    